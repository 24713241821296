import { FORM_TEXTS } from '@jotforminc/constants';

// ********** INITIALIZE PART WHICH RUNS AT DOCUMENT READY - START **********
import {
  headers,
  hideJotFormInputs,
  initializeOtherInputs,
  removeEmptyLabels,
  removeDublicateLabels,
  replaceImagesWithDiv,
  setQuestionOrder,
  correctHeadingStructure
} from './enhancer';

import {
  domSelect,
  elementFactory,
  findParentBySelector,
  removeEmptyAttributes,
  getClosest
} from './domUtils';

import { Texts } from './constants';

import './accessibilityEnhancer.css';

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
// Prepend polyfill
(function arrFn(arr) {
  arr.forEach(item => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('prepend')) {
      return;
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend(...params) {
        const argArr = [...params];
        const docFrag = document.createDocumentFragment();

        argArr.forEach(argItem => {
          const isNode = argItem instanceof window.Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.insertBefore(docFrag, this.firstChild);
      }
    });
  });
}([window.Element.prototype, window.Document.prototype, window.DocumentFragment.prototype]));

// ********** INITIALIZE PART WHICH RUNS AT DOCUMENT READY - END **********

function recalculateHeight() {
  if (window.JotForm.handleIFrameHeight) window.JotForm.handleIFrameHeight();
}

function isSectionCollapse(el) {
  if (!el) {
    return false;
  }
  const firstChild = el.firstElementChild;
  return firstChild && firstChild.getAttribute('data-type') === 'control_collapse';
}

function setDefaultLanguage(document) {
  const html = document.querySelector('html');
  if (!html.hasAttribute('lang')) {
    html.setAttribute('lang', 'en');
  }
}

function setAltTextsToImages(document) {
  const imagesWithoutAlt = document.querySelectorAll('img:not([alt])');
  imagesWithoutAlt.forEach(image => {
    image.setAttribute('alt', '');
  });
}

// Wraps collapse ul's with li's
function wrapSections() {
  // Select all containers in form
  const formContainers = domSelect(document)('ul.form-section-closed');

  for (let i = 0; i < formContainers.length; i++) {
    // Create li element
    const wrapperLi = document.createElement('li');
    // Put new li just before ul
    formContainers[i].parentNode.insertBefore(wrapperLi, formContainers[i]);
    // Put ul inside new created li
    wrapperLi.appendChild(formContainers[i]);
  }
}

// Copy error message of given error dom
function replicateErrorMessage(errorContainer, errorElement) {
  const wrapper = document.createElement('div');
  wrapper.textContent = errorElement.textContent.trim();
  // eslint-disable-next-line no-param-reassign
  errorContainer.innerHTML = wrapper.outerHTML;
}

// Adds label of question to errors and copy it to the new error element
function prependFieldNameToError(errorElement, parentLi) {
  // Get label of the question
  const label = parentLi.querySelector('label');

  /* checkbox and radio are now accessible by default
  switch (parentLi.getAttribute('data-type')) {
    case 'control_checkbox':
    case 'control_radio' :
      label = parentLi.querySelector('legend');
      break;
    default:

      break;
  }
  */

  // If label exists
  if (label) {
    // Get label content
    let labelContent = label.textContent.replace(/\s\s+/g, ' ').replace(/\r?\n|\r/g, '').trim();

    // If the field is required
    if (label.querySelector('span.form-required')) {
      // Remove the asterisk at the end
      labelContent = labelContent.slice(0, -1);
    }
    // Set the error message
    const span = document.createElement('span');
    span.textContent = `"${labelContent.trim()}" ${window.JotForm.texts.fieldError || FORM_TEXTS.fieldError || Texts.FIELD_ERROR}`;
    errorElement.prepend(span);
  }
}

function getFirstInputElement(parentEl) {
  let firstElement = null;
  switch (parentEl.getAttribute('data-type')) {
    case 'control_checkbox':
      firstElement = parentEl.querySelector('input[type="checkbox"]');
      break;
    case 'control_radio':
      firstElement = parentEl.querySelector('input[type="radio"]');
      break;
    case 'control_datetime': {
      const elements = parentEl.querySelectorAll('input, select');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].value.length === 0 && elements[i].parentNode.parentNode.style.display !== 'none') {
          firstElement = elements[i];
          break;
        }
      }
      if (firstElement === null) {
        [firstElement] = elements;
      }
      break;
    }
    default: {
      const elements = parentEl.querySelectorAll('input:not([type="hidden"]), select, textarea');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].value.length === 0) {
          firstElement = elements[i];
          break;
        }
      }
      if (firstElement === null) {
        [firstElement] = elements;
      }
      break;
    }
  }
  return firstElement;
}

// Wraps content of given element with anchor which indicates given item with id
function wrapByAnchor(el, parentEl, errorMessage) {
  // Create temp anchor element
  const anchor = document.createElement('a');

  // Create firstEl variable
  let firstEl = null;

  // Get first element depending on the message type
  switch (errorMessage) {
    case 'This field is required.':
      firstEl = getFirstInputElement(parentEl);
      break;
    default:
      firstEl = parentEl.querySelector('input:not([type="hidden"]), select, textarea');
      break;
  }

  if (firstEl != null) {
    // Initialize href to first input under that element
    anchor.href = `#${firstEl.id}`;
  } else {
    // Set href to parent element
    anchor.href = `#${parentEl.id}`;
  }

  anchor.setAttribute('tabindex', 0);
  anchor.setAttribute('data-before', `${window.JotForm.texts.error || FORM_TEXTS.error || Texts.ERROR}`);

  // Copy content of given element to anchor
  anchor.innerHTML = el.innerHTML;

  // Clear content of element and append it to the anchor
  // eslint-disable-next-line no-param-reassign
  el.innerHTML = '';
  el.appendChild(anchor);
  anchor.focus();
}

// Create new error element respect to original one
function createNewErrorElement(errorElementType, originalErrorElement) {
  // Temporary error element
  const errorElement = document.createElement(errorElementType);

  // Get parent li item for copying id
  const parentLi = findParentBySelector(originalErrorElement, 'li.form-line');

  // No need to contunoe if parentLi could not be found
  if (!parentLi) {
    return false;
  }

  // Create id for new error list item
  errorElement.id = `error_${parentLi.id}`;
  errorElement.setAttribute('question-order', parentLi.getAttribute('question-order'));

  // Searching for error message for if it is already exist
  const previousNewError = document.getElementById(errorElement.id);

  // Remove currently exist error item if already exist
  if (previousNewError) {
    previousNewError.remove();
  }

  // Replicate the original error element into the new one
  replicateErrorMessage(errorElement, originalErrorElement);

  // Prepend the field name into error message
  prependFieldNameToError(errorElement, parentLi);

  // Wrap by an anchor element for accessing the related question to error
  wrapByAnchor(errorElement, parentLi, originalErrorElement.textContent.trim());

  // Section collapses should be visible
  const parentUl = findParentBySelector(parentLi, 'ul');
  const inSectionCollapse = isSectionCollapse(parentUl);
  const sectionCollapse = inSectionCollapse && parentUl.querySelector('li[data-type="control_collapse"] .form-collapse-table');
  if (sectionCollapse) {
    errorElement.addEventListener('click', () => {
      if (parentUl.hasClassName('form-section-closed')) {
        sectionCollapse.click();
      }
    });
  }
  return errorElement;
}

// Creates new error container
function createErrorContainer() {
  // Adding specific classes to access them later
  const div = elementFactory(document)('div', {
    class: 'error-div',
    'aria-live': 'polite',
    'aria-atomic': 'true',
    'aria-relevant': 'additions text'
  });
  const ul = elementFactory(document)('ul', {
    class: 'error-list'
  });
  // Appending message div and ul to main container
  div.appendChild(ul);
  return div;
}

// Creates list item
function createListItem(content) {
  // Create temporary list item
  const li = elementFactory(document)('li', {
    'aria-hidden': 'true',
    class: 'a11y-error-container'
  });
  // Append the content
  li.appendChild(content);

  return li;
}

// Inserts required dom containers to form
function initializeContainers(callback) {
  // Getting all form containers
  const formContainers = domSelect(document)('ul.form-section.page-section');

  for (let i = 0; i < formContainers.length; i++) {
    // Get first question list item and first span that indicates field requirement
    let firstLi = formContainers[i].querySelector('li.form-line');
    const reqSpans = formContainers[i].querySelectorAll('span.form-required');

    const parentUl = findParentBySelector(firstLi, 'ul.form-section-closed') || findParentBySelector(firstLi, 'ul.form-section');
    const inSectionCollapse = isSectionCollapse(parentUl);
    if (inSectionCollapse) {
      firstLi = parentUl;
    }

    // If a requirement field is exist
    if (reqSpans.length > 0) {
      // Create a li item as a wrapper for legend
      const tempLi = document.createElement('li');
      tempLi.setAttribute('class', 'a11y_requirement_desc_line');
      // Create a legend item and set required attributes
      const tempLegend = document.createElement('legend');
      tempLegend.innerHTML = window.JotForm.texts.requiredLegend || FORM_TEXTS.requiredLegend || Texts.LEGEND_REQUIRED;
      // tempLegend.innerHTML = legendText; // Errors section title
      tempLegend.id = `requirement_description_${i}`;

      tempLi.appendChild(tempLegend);

      // Insert a legend which explains required fields
      formContainers[i].insertBefore(tempLi, firstLi);

      // Add legend as description to all spans
      for (let j = 0; j < reqSpans.length; j++) {
        reqSpans[j].setAttribute('aria-label', 'Required');
        reqSpans[j].setAttribute('aria-describedby', `requirement_description_${i}`);
      }
    }

    // Create temporary error container
    const tempContainer = createErrorContainer();

    // Insert the error container after wrapping with list item
    formContainers[i].insertBefore(createListItem(tempContainer), firstLi);
    recalculateHeight();
  }

  if (callback && typeof callback === 'function') {
    callback();
  }
}

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
};

// Handler for main error replicatoon
function handleMainErrorReplicate(self) {
  // Check if form exists from ul selector
  const parentUl = findParentBySelector(self, 'ul.form-section.page-section');

  // If parent ul could not be found (which means form structure is not as expected or form does not exist at all)
  if (!parentUl) {
    return false;
  }
  const errorField = self.querySelector('div.form-button-error');
  // It means error field exists && check attribute to prevent infinite loop
  if (errorField !== null && errorField.style.display !== 'none') {
    errorField.style.display = 'none';
  }
}

function handleSubmitClick(self) {
  // Get parent ul
  const parentUl = findParentBySelector(self, 'ul.form-section.page-section');

  // If parent ul could not be found (which means form structure is not as expected or form does not exist at all)
  if (!parentUl) {
    return;
  }

  // Get an error element to check if one exist
  setTimeout(() => {
    const errorElement = parentUl.querySelector('ul.error-list li');

    // If error element exist
    if (errorElement) {
      // Get anchor element of first error
      const anchor = errorElement.querySelector('a');

      // If anchor exists then focus to it
      if (anchor) {
        anchor.focus();
      }
    }
  }, 400);
}

function sortList(list) {
  // Sorting variables
  let i; let switching; let b; let
    shouldSwitch;
  switching = true;

  /* Make a loop that will continue until
  no switching has been done: */
  while (switching) {
    // start by saying: no switching is done:
    switching = false;
    b = list.getElementsByTagName('LI');
    // Loop through all list-items:
    for (i = 0; i < (b.length - 1); i++) {
      // start by saying there should be no switching:
      shouldSwitch = false;
      /* check if the next item should
      switch place with the current item: */
      if (Number(b[i].getAttribute('question-order')) > Number(b[i + 1].getAttribute('question-order'))) {
        /* if next item is alphabetically
        lower than current item, mark as a switch
        and break the loop: */
        shouldSwitch = true;
        break;
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark the switch as done: */
      b[i].parentNode.insertBefore(b[i + 1], b[i]);
      switching = true;
    }
  }
}

// Wraps given error element to a given wrapper and append it to the new error container
function createNewErrorListItem(errorContainer, originalErrorElement) {
  const tempErrorElement = createNewErrorElement('li', originalErrorElement);
  errorContainer.appendChild(tempErrorElement);

  sortList(errorContainer);
}

// Handler for fields error replication
function handleFieldErrorReplicate(self, e) {
  e.stopImmediatePropagation();

  const errorField = self.querySelector('div.form-error-message');
  const errorLi = getClosest(self)('.a11y-error-container');
  if (errorField !== null) { // It means error field exists
    // IE11 sees attribute changes as doom update and causes infinite recursion
    if (!(errorField.getAttribute('class').indexOf('screenreader') > -1) || !(errorField.getAttribute('role') === 'alert')) {
      errorField.classList.add('screenreader');
      errorField.setAttribute('role', 'alert');
    }
    const errorIcon = errorField.querySelector('img');
    if (errorIcon) {
      setTimeout(() => {
        errorIcon.remove();
      }, 10);
    }
    const parentUl = findParentBySelector(errorField, 'ul.form-section.page-section');
    if (parentUl) {
      const errorContainer = parentUl.querySelector('ul.error-list');
      errorLi.setAttribute('aria-hidden', false);
      createNewErrorListItem(errorContainer, errorField);
    }
  } else if (self.id) {
    // It means error field does not exist and, if possible, delete the newly created clone on that case
    // Get id of container and remove the unnecessary part
    // Get number part of the id
    const idNumeric = self.id.replace(/\D/g, '');

    // Search for cloned error
    const clonedError = document.getElementById(`error_id_${idNumeric}`);

    // If the cloned error exist then remove it
    if (clonedError) {
      clonedError.remove();
    }
    if (domSelect(document)('li[id*="error_id"]').length === 0) {
      errorLi.setAttribute('aria-hidden', true);
    }
  }
}

// Method to bind an event to multiple items that matches the given selector
function bindHandlerToMultipleElements(selector, event, handler) {
  const elements = document.body.querySelectorAll(selector);

  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener(event, function elementFn(e) {
      handler(this, e);
    });
  }
}

// Initialize submit handlers
function initializeSubmitHandlers() {
  bindHandlerToMultipleElements('button[type="submit"], button.form-pagebreak-next', 'click', handleSubmitClick);
}

// J3 validations intersects with accessibility errors, this validations should be executed last on call stack.
const debouncedHandleFieldErrorReplicate = debounce(handleFieldErrorReplicate, 0);

// Initialize error replication events
function initializeErrorHandlers() {
  // Binds replicate event of main error which next to submit button generally
  bindHandlerToMultipleElements('div.form-pagebreak, li[data-type="control_button"]', 'DOMSubtreeModified', handleMainErrorReplicate);

  // Bind replicate event of field errors
  bindHandlerToMultipleElements('li.form-line', 'DOMSubtreeModified', debouncedHandleFieldErrorReplicate);
}

function loadLegendTranslation(getTranslations) {
  const translations = JSON.parse(getTranslations.target.response);
  if (translations && translations.content && typeof translations.content === 'object') {
    document.querySelectorAll('legend[id*="requirement_description_"]').forEach(e => {
      e.innerHTML = translations.content[e.innerHTML] || e.innerHTML;
    });
    document.querySelectorAll('span.form-required').forEach(e => {
      const _requiredText = e.getAttribute('aria-label');
      if (_requiredText) {
        e.setAttribute('aria-label', translations.content[_requiredText] || _requiredText);
      }
    });
  }

  // recalculate height after lots of DOM updates
  recalculateHeight();
}

// Translations
function handleTranslationLoader() {
  const _langCode = document.querySelector('html').getAttribute('lang');

  if (typeof _langCode === 'string' && _langCode !== 'en' && _langCode !== 'en-US') {
    // No need to run translationList request if the language is English
    const data = Object.values(Texts);
    const getTranslations = new window.XMLHttpRequest();
    getTranslations.addEventListener('load', loadLegendTranslation);
    getTranslations.open('POST', `https://api.jotform.com/translationList?data=${JSON.stringify(data)}&lang=${_langCode}`);
    getTranslations.send();
  }
}

// ********** INITIALIZE PART WHICH RUNS AT DOCUMENT ONLOAD **********
window.onload = () => {
  hideJotFormInputs(document);
  removeEmptyAttributes(document)(['aria-labelledby', 'placeholder']);
  replaceImagesWithDiv(document);
};

(() => {
  const initialize = () => {
    // Set a default language for the page if not set
    setDefaultLanguage(document);
    // Set question order for decide order of error elements
    setQuestionOrder(document);
    // Initialize error containers
    initializeContainers(handleTranslationLoader);
    // Wrap Sections with li tags
    wrapSections(document);
    // Initialize event binds to replace error divs
    initializeErrorHandlers();
    // Initialize submit handler
    initializeSubmitHandlers();
    // Form field must not have dublicate labels
    removeDublicateLabels(document);
    // ensure we have h1
    headers(document);
    // do not skip heading ranks
    correctHeadingStructure(document);
    // group form elements together
    // groupFormElements(document);
    // give images empty alt texts if not specified
    setAltTextsToImages(document);
    // Translations

    initializeOtherInputs(document);
    removeEmptyLabels(document);
  };

  if (window.JotForm.initializing === false) { // jotform init scripts already finished running
    initialize();
  } else {
    document.addEventListener('JotformReady', initialize);
  }
})();
