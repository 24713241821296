/* eslint-disable import/no-extraneous-dependencies */
import map from 'crocks/pointfree/map';
import compose from 'crocks/helpers/compose';

export const log = m => x => console.log(`${m} - ${x}`) || x; // eslint-disable-line
// clear attribute value of element
export const clearAttribute = attribute => element => (
  element.setAttribute(attribute, '') || element
);

export const removeAttributesFromElement = attributes => element => (
  attributes.forEach(attr => element.removeAttribute(attr)) || element
);

export const domSelect = document => selector => (
  document.querySelectorAll(selector)
);

export const getClosest = element => query => (
  element
    ? element.querySelector(query) || getClosest(element.parentNode)(query)
    : null
);

export const setElementAttributes = element => attributes => {
  if (attributes && typeof attributes === 'object') {
    Object.keys(attributes).forEach(attr => element.setAttribute(attr, attributes[attr]));
  }
  return element;
};

export const setElementStyle = element => styles => {
  if (styles && typeof styles === 'object') {
    Object.keys(styles).forEach(style => {
      // eslint-disable-next-line no-param-reassign
      element.style[style] = styles[style];
    });
  }
  return element;
};

export const addPropertiesToElement = element => properties => {
  if (properties && typeof properties === 'object') {
    Object.keys(properties).forEach(prop => {
      // eslint-disable-next-line no-param-reassign
      element[prop] = properties[prop];
    });
  }
  return element;
};

export const elementFactory = document => (elementType, attributes, properties) => {
  const element = document.createElement(elementType);
  setElementAttributes(element)(attributes);
  addPropertiesToElement(element)(properties);
  return element;
};

export const xPathToArray = xPathResult => {
  const resArray = [];
  let currentItem = xPathResult.iterateNext();
  while (currentItem) {
    resArray.push(currentItem);
    currentItem = xPathResult.iterateNext();
  }
  return resArray;
};

export const xPathSelector = document => xPathQuery => (
  xPathToArray(document.evaluate(xPathQuery, document, null, window.XPathResult.ANY_TYPE, null))
);

export const getNodesAsArray = document => query => (
  Array.from(domSelect(document)(query))
);

export const removeElement = element => {
  return element ? element.remove() : null;
};

export const removeEmptyAttribute = document => attribute => (
  compose(
    map(removeAttributesFromElement([attribute]))
  )(xPathSelector(document)(`//*[@${attribute}[not(normalize-space(string()))]]`))
);

export const removeEmptyAttributes = document => attributes => (
  compose(
    map(removeEmptyAttribute(document))
  )(attributes)
);

// Checks if a object has given element
export const collectionHas = (a, b) => {
  for (let i = 0, len = a.length; i < len; i++) {
    if (a[i] == b) return true; // eslint-disable-line
  }
  return false;
};

// Finds parent of an element by using recursive calls of collectionHas function
export const findParentBySelector = (elm, selector) => {
  const all = domSelect(document)(selector);
  let cur = elm.parentNode;
  while (cur && !collectionHas(all, cur)) { // keep going up until you find a match
    cur = cur.parentNode; // go up
  }
  return cur; // will return null if not found
};
