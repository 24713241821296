/* eslint-disable import/no-extraneous-dependencies */
import map from 'crocks/pointfree/map';
import compose from 'crocks/helpers/compose';
import filter from 'crocks/pointfree/filter';
import Maybe from 'crocks/Maybe';
import {
  domSelect,
  elementFactory,
  getClosest,
  getNodesAsArray,
  removeAttributesFromElement,
  removeElement,
  setElementAttributes,
  setElementStyle
} from './domUtils';

const { Just, Nothing } = Maybe;
// find all labels having 'topLabelFor' as "for" property' value
const labelsByFor = document => topLabelFor => (
  domSelect(document)(`label[for="${topLabelFor}"]`)
);

// find if there are multiple labels pointing to the same input, return Boolean
const hasMoreThanOne = document => label => (
  labelsByFor(document)(label.getAttribute('for')).length > 1
);

// get top labels for each question
const topLabels = document => (
  getNodesAsArray(document)('li.form-line>label.form-label')
);

// https://bit.ly/2U7xzAp
export const removeDublicateLabels = document => ( // eslint-disable-line
  compose(
    map(removeAttributesFromElement(['for'])), // clear for attributes for each dublicate labels
    filter(hasMoreThanOne(document)) // filter out labels with only one presence
  )(topLabels(document))
);

// create a new HTML element
const createH1 = document => ({ h2 }) => ({
  h2,
  h1: document.createElement('h1')
});

// check if there is an h2 but not h1
const checkHeaders = ({ h1, h2 }) => h1 === null && h2 !== null;

// ensure if header creation is necessary
const ensureHeaderNecessary = headers => (
  checkHeaders(headers) ? Just(headers) : Nothing('Header check no needed')
);

// copy h2Existing child nodes into h1New
const copyChildNodes = ({ h1, h2 }) => (
  [...h2.childNodes].forEach(node => h1.appendChild(node)) || { h1, h2 }
);

// copy all attributes
const copyAttributes = ({ h1, h2 }) => (
  [...h2.attributes].forEach(
    attribute => h1.setAttribute(attribute.nodeName, attribute.nodeValue)
  ) || { h1, h2 }
);

const replaceHeaders = ({ h1, h2 }) => (
  h2.parentNode.replaceChild(h1, h2) || { h1, h2 }
);

const findSecondaryHeader = () => {
  let header = document.querySelector('h2');
  if (header == null) {
    header = document.querySelector('h3');
  }
  return header;
};

// TODO: copy attached event listeners
export const headers = document => compose(
  map(replaceHeaders),
  map(copyAttributes),
  map(copyChildNodes),
  map(createH1(document)),
  ensureHeaderNecessary
)({ h1: document.querySelector('h1'), h2: findSecondaryHeader() });

const increaseHeadingLevel = (document, element) => {
  const tag = element.tagName === 'H3' ? 'h2' : 'h3';
  const newHeading = document.createElement(tag);
  const pair = { h1: newHeading, h2: element };
  copyChildNodes(pair);
  copyAttributes(pair);
  replaceHeaders(pair);
  return pair;
};

export const correctHeadingStructure = document => {
  let headings = document.querySelectorAll('h1, h2, h3, h4');
  let previousElement = headings[0];
  [...headings].forEach(element => {
    if ((previousElement.tagName === 'H1' && element.tagName === 'H3') || (previousElement.tagName === 'H2' && element.tagName === 'H4')) {
      const newPair = increaseHeadingLevel(document, element);
      // eslint-disable-next-line no-param-reassign
      element = newPair.h1;
    } else {
      previousElement = element;
    }
  });
  headings = document.querySelectorAll('h1, h2, h3, h4');
};

const ensureGrouppingNecessary = field => (
  field.querySelector('fieldset') === null
    ? Just(field)
    : Nothing('There is already fieldset')
);

const getSafeInnerText = element => {
  const label = getClosest(element)('label.form-label');
  return label ? label.innerText : 'Form Group';
};

const legendFactory = document => text => (
  elementFactory(document)('legend', {
    style: 'text-align:left;width:initial',
    class: 'a11y-legend'
  }, {
    innerText: text
  })
);

const createFieldset = document => element => ({
  element,
  fieldset: document.createElement('fieldset')
});

const createLegend = document => ({ element, fieldset }) => ({
  element,
  fieldset,
  legend: legendFactory(document)(getSafeInnerText(element))
});

const appendFieldsetToElement = ({ element, fieldset, legend }) => {
  const label = getClosest(element)('label.form-label');
  if (label) {
    const labelID = label.getAttribute('id');
    legend.setAttribute('id', labelID);
    label.remove();
  }
  return element.appendChild(fieldset);
};

const createChildElements = ({ element, fieldset, legend }) => (
  [...element.childElements()].forEach(el => fieldset.appendChild(el)) || {
    element, fieldset, legend
  }
);

const appendLegendToFieldset = ({ element, fieldset, legend }) => (
  fieldset.appendChild(legend) && { element, fieldset, legend }
);

const groupSingleElement = document => element => compose(
  map(appendFieldsetToElement), // 6. append fieldset to original element
  map(createChildElements), // 5. move child elements from original element to fieldset
  map(appendLegendToFieldset), // 4. append legend to fieldset
  map(createLegend(document)), // 3. create legend
  map(createFieldset(document)), // 2. create fieldset
  ensureGrouppingNecessary // 1. Do we need to create fieldset and legend fields?
)(element);

const getGrouppingElements = document => (
  getNodesAsArray(document)('div[data-component="radio"],div[data-component="checkbox"]')
);

export const groupFormElements = document => (
  getGrouppingElements(document).map(groupSingleElement(document))
);

const getAllOtherInputs = document => (
  getNodesAsArray(document)('[type="radio"].form-radio-other,[type="checkbox"].form-checkbox-other')
);

// TODO: Rewrite this function to fit FP Concepts
export const initializeOtherInputs = document => {
  getAllOtherInputs(document).forEach(i1 => {
    // Get Text Input
    const otherInput = getClosest(i1)('.form-textbox');
    otherInput.setAttribute('aria-label', 'Other Option');
    i1.setAttribute('aria-label', otherInput.getAttribute('data-otherhint').trim());
    const otherLabel = getClosest(i1)('label');
    if (otherLabel && otherLabel.innerText.trim().length === 0) {
      otherLabel.remove();
    }
  });
};

// Also we need to filter editable labels from other input field values
const getEmptyLabels = document => Array.from(document.querySelectorAll('label')).filter(label => label.innerHTML.trim() === '');

/* const labelsUsedInARIA = (document) => (label) => (
  domSelect(document)(`[aria-labelledby*="${label.id}"]`).length > 0
); */

/* export const removeForAttributes = (document) => (
  compose(
    map(removeAttributesFromElement(['for'])),
    filter(labelsUsedInARIA(document))
  )(getNodesAsArray(document)('label[for]'))
); */

const findLabelRelatedFields = document => label => (
  label && label.id
    ? { label, relatedFields: getNodesAsArray(document)(`[aria-labelledby*="${label.id}"]`) }
    : { label }
);

const removeLabelIdFromElement = label => field => {
  field.setAttribute('aria-labelledby', field.getAttribute('aria-labelledby').replace(label.id, '').trim());
  return label;
};

const removeLabelIdFromFields = ({ label, relatedFields }) => {
  return label && label.id
    ? compose(
      map(removeElement),
      map(removeLabelIdFromElement(label))
    )(relatedFields)
    : null;
};

export const removeEmptyLabels = document => (
  compose(
    map(removeLabelIdFromFields),
    map(findLabelRelatedFields(document))
  )(getEmptyLabels(document))
);

const createImageDiv = document => imgElement => {
  const newDiv = elementFactory(document)('div', {
    class: 'form-image'
  });
  setElementStyle(newDiv)({
    backgroundImage: `url("${imgElement.getAttribute('src')}")`,
    backgroundSize: 'contain',
    height: `${imgElement.height}px`,
    width: `${imgElement.width}px`
  });
  return { imgElement, newDiv };
};

const appendDivToImgParent = ({ imgElement, newDiv }) => {
  imgElement.parentElement.appendChild(newDiv);
  imgElement.remove();
};

export const replaceImagesWithDiv = document => (
  compose(
    map(appendDivToImgParent),
    map(createImageDiv(document))
  )(getNodesAsArray(document)('img.form-image[alt=""]'))
);

const hideInputs = element => {
  setElementAttributes(element)({ type: 'hidden' });
};

export const hideJotFormInputs = document => (
  compose(
    map(hideInputs)
  )(getNodesAsArray(document)('input[name="input_language"],input[name="website"]'))
);

const addOrderAttribute = (element, index) => (
  setElementAttributes(element)({
    'question-order': index
  })
);

// TODO: Rewrite this function to fit FP Concepts
export const setQuestionOrder = document => (
  getNodesAsArray(document)('li.form-line').forEach(addOrderAttribute)
);
